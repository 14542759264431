import { useEffect, useState } from 'react'
import { Link } from 'gatsby'
import Layout from '../components/Layout'
import Pagination from '../components/Pagination'
import SpinLoading from '../components/SpinLoading'
import Toast from '../components/toast'
import screenIcon from '../images/market/screenIcon.png'
import trendIcon from '../images/market/trendIcon.png'
import upTrendChart from '../images/market/upTrendChart.png'
import downTrendChart from '../images/market/downTrendChart.png'
import bgRight from '../images/market/bgRight.png'
import { mock } from 'mockjs'

import avater1 from '../images/common/avater1.png'
import avater2 from '../images/common/avater2.png'
import avater3 from '../images/common/avater3.png'
import avater4 from '../images/common/avater4.png'
import avater5 from '../images/common/avater5.png'
import avater6 from '../images/common/avater6.png'

const Market: React.FC = () => {
  const [curTableOption, setCurTableOption] = useState(0)
  const [showInputSelectList, setShowInputSelectList] = useState(false)
  const [showlatestPrice, setShowlatestPrice] = useState(false)
  const [tableLoading, setTableLoading] = useState(false)

  const [overviewList] = useState([
    mock({
      iconName: 'ETH',
      price: '$ @float(0,1000,2,2)T',
      percent: '@float(-99,99,2,2)',
    }),
    mock({
      iconName: 'CTIO',
      price: '$ @float(0,1000,2,2)T',
      percent: '@float(-99,99,2,2)',
    }),
  ])
  const [searchList] = useState(
    new Array(30).fill('').map(() =>
      mock({
        avater: [avater1, avater2, avater3, avater4, avater5, avater6][
          mock('@integer(0,5)')
        ],
        userName: '@name()',
        coin: 'ETH',
        price: '$ @float(0,10000,2,2)',
      }),
    ),
  )
  const [coinList, setCoinList] = useState(
    new Array(10).fill('').map(() =>
      mock({
        avater: [avater1, avater2, avater3, avater4, avater5, avater6][
          mock('@integer(0,5)')
        ],
        name: '@name()',
        latestPrice: '$ @float(0,10000,2,2)',
        change: '@float(-99,99,2,2)',
        value: '$ @float(0,1000,2,2)',
        collectState: '@boolean',
      }),
    ),
  )

  const [searchModalVisible, setSearchModalVisible] = useState(false)
  const [mobileSearchList, setMobileSearchList] = useState([
    {
      title: 'Types of trend',
      item: [
        { value: 'All', checked: false },
        { value: 'AMM', checked: false },
        { value: 'Margin', checked: false },
        { value: 'Coin', checked: false },
        { value: 'NFT', checked: false },
        { value: 'Token', checked: false },
        { value: 'Privacy', checked: false },
      ],
    },
    {
      title: 'Select time',
      item: [
        { value: 'Latest Price', checked: false },
        { value: '24H Gain', checked: false },
        { value: '24H Loss', checked: false },
        { value: '30D Gain', checked: false },
        { value: '30D Loss', checked: false },
        { value: '24H Value', checked: false },
        { value: 'Listing time', checked: false },
      ],
    },
  ])

  const onPageChange = async (page: number) => {
    if (typeof window !== 'undefined') {
      window?.scroll({ top: 365, behavior: 'smooth' })
    }
    setTableLoading(true)
    const curPage = await new Promise(resolve =>
      setTimeout(resolve, 1200, page),
    )
    setTableLoading(false)
    console.log('curPage: ', curPage)
  }
  useEffect(() => {}, [])

  return (
    <Layout>
      <Toast />
      <div className="text-sm font-regularText">
        {/* Title content */}
        <div className="pt-20 mx-auto bg-[url('../images/market/bgBanner.png')] bg-no-repeat h-[422px] bg-cover sm:pt-[57px] ">
          <div className="flex justify-between mx-auto mt-16 max-w-7xl md:px-16 md:w-auto sm:p-0">
            <div className="md:z-10 w-[444px] h-[226px] text-black leading-[52px] sm:flex-1">
              <h3 className="h-[52px] text-[34px] font-bold sm:text-[26px] sm:mx-4">
                Trading market
              </h3>
              <div className="h-5 mt-3 text-sm sm:w-[150px] sm:h-auto sm:text-[11px] sm:mx-4">
                In depth understanding of coin market
              </div>
              {/* Search Input */}
              <div className="relative mt-7 sm:hidden">
                <input
                  type="text"
                  placeholder="Search by coin or token name"
                  className="py-4 pr-[50px] pl-5 w-full	h-[52px] bg-white shadow-[0px_16px_24px_1px] shadow-[rgba(0,0,0,0.04)] rounded-[60px] text-sm text-[#BDBDBD] leading-5 placeholder:text-[#BDBDBD] outline-none"
                  onClick={() => setShowInputSelectList(!showInputSelectList)}
                />
                <div
                  className={`absolute bg-white w-[444px] h-0 shadow-[0px_16px_24px_1px_rgba(0,0,0,0.04)] left-0 top-[66px] rounded-[16px] z-10 transition-all overflow-hidden ${
                    showInputSelectList ? 'h-[500px]' : ''
                  }`}
                >
                  <div className="mt-[30px] mr-2 mb-[38px] ml-6 ">
                    <div className="text-base font-bold mb-[18px]">COINS</div>
                    <ul className=" h-[380px] overflow-y-auto scrollbar-style">
                      {searchList.map((x, i) => (
                        <li
                          key={i}
                          className="flex justify-between h-[38px] leading-[38px] hover:bg-[#F0F8FF] group cursor-pointer"
                          onClick={async () => {
                            setShowInputSelectList(!showInputSelectList)
                            setTableLoading(true)
                            await new Promise(resolve =>
                              setTimeout(resolve, 1200),
                            )
                            setTableLoading(false)
                          }}
                        >
                          <div className="flex items-center flex-1">
                            <img
                              className="w-6 h-6 rouned-[50%]"
                              alt="avater"
                              src={x.avater}
                            >
                              {x.avatar}
                            </img>
                            <span className="mx-2">{x.userName}</span>
                            <span className="text-[#828282]">{x.coin}</span>
                          </div>
                          <div className="text-[#828282] group-hover:text-[#1E94FC] leading-[38px] mr-3">
                            {x.price}
                          </div>
                        </li>
                      ))}
                    </ul>
                  </div>
                </div>
                <div className="absolute w-6 h-6 top-[50%] right-5 -translate-y-2/4 cursor-pointer">
                  <svg
                    className="w-6 h-6 text-[#4f4f4f]"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    strokeWidth={2}
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"
                    />
                  </svg>
                </div>
              </div>
              {/* Mobile Info */}
              <div className="flex mt-11 sm:mt-16 sm:bg-[rgba(255,245,245,0.8)] sm:justify-between sm:items-center sm:pt-6 sm:pr-[27px] sm:p-[25px] sm:pb-14 rounded-t-3xl sm:w-full">
                {overviewList.map((x, i) => (
                  <div key={i}>
                    <div className="flex items-start text-sm h- font-regularText pr-14 last:pr-0 sm:p-0 sm:flex-col">
                      <div className="text-sm font-regularText">
                        {x.iconName}
                      </div>
                      <span className="ml-3 sm:ml-0 sm:mt-[11px] sm:text-3xl font-numberText">
                        {x.price}
                      </span>
                      <span className="ml-3 sm:ml-0 sm:mt-[11px] flex font-numberText items-center">
                        <span
                          className={
                            x.percent > 0 ? 'text-[#3AD29F]' : 'text-[#F55F44]'
                          }
                        >
                          {x.percent}
                        </span>
                        <svg
                          className={`w-[14px] h-[14px] mr-[3px] ${
                            x.percent > 0 ? 'text-[#3AD29F]' : 'text-[#F87575]'
                          }`}
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke="currentColor"
                          strokeWidth={2}
                        >
                          {x.percent > 0 ? (
                            <path
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              d="M8 7l4-4m0 0l4 4m-4-4v18"
                            />
                          ) : (
                            <path
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              d="M19 14l-7 7m0 0l-7-7m7 7V3"
                            />
                          )}
                        </svg>
                      </span>
                    </div>
                    {!i && (
                      <div className="hidden w-8 h-8 rotate-[25deg] mt-2 border-l border-[#BDBDBD] border-solid sm:block translate-x-[50%]"></div>
                    )}
                  </div>
                ))}
              </div>
            </div>
            {/* BG Right */}
            <img
              src={bgRight}
              alt="bgRight"
              className="max-w-[310px] md:absolute md:right-16 sm:right-4 md:h-52"
            />
          </div>
        </div>
        {/* PC TableList */}
        <div className="mx-auto mt-14 max-w-7xl md:px-16 sm:hidden">
          {/* Table Options */}
          <div className="flex justify-between h-12">
            <div className="whitespace-nowrap relative flex-1 min-w-[510px] md:h-[60px] overflow-hidden overflow-x-auto scrollbar-style">
              <div
                className="absolute top-0 w-20 h-[46px] rounded-[35px] bg-[#DBEEFF] -z-10 transition-all duration-200"
                style={{ left: 112 * curTableOption }}
              />
              {['All', 'AMM', 'Margin', 'Coin', 'NFT', 'Token', 'Privacy'].map(
                (x, i) => (
                  <button
                    key={i}
                    onClick={async () => {
                      setCurTableOption(i)
                      setTableLoading(true)
                      await new Promise(resolve => setTimeout(resolve, 1200))
                      setTableLoading(false)
                    }}
                    className={`h-[46px] text-[#828282] cursor-pointer w-20 mr-8 py-[14px] px-[18px] ${
                      i === curTableOption ? 'text-[#1E94FC]' : ''
                    }`}
                  >
                    {x}
                  </button>
                ),
              )}
            </div>
            <div className="relative">
              <button
                className="h-[46px] text-[#828282] cursor-pointer mr-8 py-[14px] px-[18px] w-[135px] rounded-[39px] border-[#E0E0E0] border-[1px]"
                onClick={() => setShowlatestPrice(!showlatestPrice)}
              >
                <div className="flex items-center justify-center">
                  Latest Price
                  <svg
                    className={`w-4 h-4 ml-[2px] transition-all duration-300 ${
                      showlatestPrice ? '' : 'rotate-90'
                    }`}
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    strokeWidth={2}
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M9 5l7 7-7 7"
                    />
                  </svg>
                </div>
              </button>
              <div
                className={`overflow-hidden bg-white absolute top-[50px] left-0 rounded-[16px] z-10 transition-all shadow-[0_16px_24px_1px_rgba(0,0,0,0.04)] ${
                  showlatestPrice ? 'max-h-[280px]' : 'max-h-0'
                }`}
              >
                <ul className="h-[180px] my-5 mr-3 overflow-hidden overflow-y-auto scrollbar-style">
                  {[
                    { value: 'Latest Price' },
                    { value: '24H Gain' },
                    { value: '24H Loss' },
                    { value: '30D Gain' },
                    { value: '30D Loss ' },
                    { value: '24H Value' },
                    { value: 'Listing time' },
                  ].map((x, i) => (
                    <li
                      key={i}
                      className="group h-[38px] leading-[38px] hover:bg-[#F0F8FF] pl-[22px] pr-5 cursor-pointer"
                      onClick={() => setShowlatestPrice(!showlatestPrice)}
                    >
                      <span className="text-[#333] group-hover:text-[#1E94FC] whitespace-nowrap">
                        {x.value}
                      </span>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          </div>
          {/* Table Content */}
          {tableLoading ? (
            <SpinLoading className="my-16" />
          ) : (
            <div className="w-full mb-16 text-base text-center mt-7 font-numberText">
              <div className="rounded-[16px] bg-[#F9F9F9] grid grid-cols-5">
                <div className="leading-[64px] h-16 text-sm font-regularText">
                  All Coins
                </div>
                <div className="leading-[64px] h-16 text-sm font-regularText">
                  Latest Price(USD)
                </div>
                <div className="leading-[64px] h-16 text-sm font-regularText">
                  24H Change
                </div>
                {/* <div className="leading-[64px] h-16 text-sm font-regularText">
                  Circulating Market Cap(USD)
                </div> */}
                <div className="leading-[64px] h-16 text-sm font-regularText">
                  24H Value(USD)
                </div>
                <div className="flex items-center justify-center leading-[64px] h-16 text-sm font-regularText">
                  <span>7-Day (Price) Trend</span>
                  <img
                    src={trendIcon}
                    alt="trendIcon"
                    className="ml-[5px] w-[22px]"
                  />
                </div>
              </div>
              <div>
                {coinList.map((x, i) => (
                  <div
                    key={i}
                    className="h-[76px] border-b border-[#f2f2f2] grid grid-cols-5"
                  >
                    <div className="leading-[76px] font-regularText text-sm flex items-center ml-8">
                      <svg
                        viewBox="0 0 20 20"
                        fill={x.collectState ? '#f87575' : '#D3D3D3'}
                        className="w-[18px] h-[18px] mr-[14px] cursor-pointer"
                        onClick={() =>
                          setCoinList(
                            coinList.map(y =>
                              y.name !== x.name
                                ? y
                                : { ...y, collectState: !x.collectState },
                            ),
                          )
                        }
                      >
                        <path
                          fillRule="evenodd"
                          d="M3.172 5.172a4 4 0 015.656 0L10 6.343l1.172-1.171a4 4 0 115.656 5.656L10 17.657l-6.828-6.829a4 4 0 010-5.656z"
                          clipRule="evenodd"
                        />
                      </svg>
                      <span>{i + 1}</span>
                      <img
                        className="w-[30px] h-[30px] rounded-[50%] mr-3 ml-6"
                        src={x.avater}
                        alt="avatar"
                      />
                      <span className="text-sm whitespace-nowrap">
                        {x.name}
                      </span>
                    </div>
                    <div className="leading-[76px]">{x.latestPrice}</div>
                    <div
                      className={`leading-[76px] ${
                        x.change > 0 ? 'text-[#3AD29F]' : 'text-[#F55F44]'
                      }`}
                    >
                      {x.change}%
                    </div>
                    <div className="leading-[76px]">{x.value}</div>
                    <div className="leading-[76px] flex justify-center items-center">
                      <img
                        src={x.change > 0 ? upTrendChart : downTrendChart}
                        alt={x.change > 0 ? 'upTrendChart' : 'downTrendChart'}
                        className="w-24 mr-6"
                      />
                      <Link
                        to="/coinDetails"
                        className="w-3 h-3 border-t border-r border-solid border-[#ccc] cursor-pointer rotate-45 hover:border-[#1E94FC]"
                      ></Link>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          )}
          <Pagination onChange={onPageChange} />
        </div>
        {/* Mobile List Content */}
        <div className="relative z-10 hidden bg-white sm:block rounded-t-3xl">
          <div className="pt-[41px] mx-4">
            <div className="flex items-center justify-between mb-8">
              <div className="text-[22px] font-bold">All Coins</div>
              <div className="flex">
                <img
                  src={screenIcon}
                  alt="searchBtn"
                  className="w-6 h-6 mr-[30px] cursor-pointer"
                  onClick={() => setSearchModalVisible(true)}
                />
                <svg
                  className="w-6 h-6 text-[#4f4f4f]"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  strokeWidth={2}
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"
                  />
                </svg>
              </div>
            </div>
            <ul className="h-[560px] overflow-hidden overflow-y-auto scrollbar-no-style">
              {coinList.map((x, i) => (
                <Link key={i} to="/coinDetails">
                  <li className="bg-white font-regularText rounded-2xl border border-solid border-[#F2F2F2] mb-5 pt-[26px] pr-[26px] pb-[26px] pl-[21px] flex items-center justify-between">
                    <img
                      className="w-[38px] h-[38px] rounded-[50%]"
                      src={x.avater}
                      alt="avatar"
                    />
                    <div className="flex flex-col ml-[13px]">
                      <div className="text-sm leading-4 mb-[6px]">ETH</div>
                      <div className="text-[#828282] font-numberText text-sm leading-4">
                        # {i + 1}
                      </div>
                    </div>
                    <div
                      className={`flex flex-col items-center ml-[35px] ${
                        x.change > 0 ? 'text-[#3AD29F]' : 'text-[#F55F44]'
                      }`}
                    >
                      <div className="text-sm leading-4 font-numberText mb-[6px]">
                        {x.latestPrice}
                      </div>
                      <div className="flex">
                        <svg
                          className={`w-[14px] h-[14px] mr-[3px] ${
                            x.change > 0 ? 'text-[#3AD29F]' : 'text-[#F87575]'
                          }`}
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke="currentColor"
                          strokeWidth={2}
                        >
                          {x.change > 0 ? (
                            <path
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              d="M8 7l4-4m0 0l4 4m-4-4v18"
                            />
                          ) : (
                            <path
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              d="M19 14l-7 7m0 0l-7-7m7 7V3"
                            />
                          )}
                        </svg>
                        <div className="text-sm leading-4 font-numberText">
                          {x.change}%
                        </div>
                      </div>
                    </div>
                    <div className="flex flex-col items-center ml-[35px]">
                      <div className="text-sm leading-4 font-numberText mb-[6px]">
                        {x.value}
                      </div>
                      <div className="text-[#828282] font-numberText text-sm leading-4">
                        24H Value
                      </div>
                    </div>
                  </li>
                </Link>
              ))}
            </ul>
          </div>
        </div>
        {/* Mobile Filter Modal */}
        <div
          className={`hidden w-full h-[100vh] backdrop-saturate-[180%]  backdrop-blur-[6px] fixed top-0 z-30 transition-all md:block ${
            searchModalVisible
              ? 'visible bg-[rgba(0,0,0,0.4)]'
              : 'invisible bg-[rgba(0,0,0,0)]'
          }`}
        >
          <div
            className={`absolute bottom-0 w-full rounded-t-[32px] bg-gradient-to-t from-[rgba(255,255,255,1)] to-[rgba(234,244,253,0.71)] transition-all ${
              searchModalVisible ? 'h-[550px]' : 'h-0'
            }`}
          >
            {/* Content */}
            <div className="mx-4">
              <div className="mt-[42px] font-boldText text-[26px] text-center">
                Advanced filter
              </div>
              <div className="h-[350px] overflow-hidden overflow-y-auto scrollbar-no-style">
                {mobileSearchList.map((x, i) => (
                  <div key={i} className="mt-[44px]">
                    <div className="text-xl font-boldText">{x.title}</div>
                    <div className="grid grid-cols-3 gap-4 mt-6">
                      {x.item.map((y, j) => (
                        <div
                          key={j}
                          className={`h-[50px] rounded-lg text-center leading-[50px] text-[17px] cursor-pointer ${
                            y.checked
                              ? 'text-[#FFF] bg-[#1E94FC]'
                              : 'bg-white text-[#4F4F4F]'
                          }`}
                          onClick={() =>
                            setMobileSearchList(
                              mobileSearchList.map(list =>
                                list.title === x.title
                                  ? {
                                      ...list,
                                      item: list.item.map(item =>
                                        item.value === y.value
                                          ? { ...item, checked: !item.checked }
                                          : item,
                                      ),
                                    }
                                  : list,
                              ),
                            )
                          }
                        >
                          {y.value}
                        </div>
                      ))}
                    </div>
                  </div>
                ))}
              </div>
              <div className="h-[44px] mt-[44px] text-[16px]">
                <button
                  className="w-[30%] bg-transparent h-full text-[#828282] rounded"
                  onClick={() =>
                    setMobileSearchList(
                      mobileSearchList.map(x => ({
                        ...x,
                        item: x.item.map(y => ({ ...y, checked: false })),
                      })),
                    )
                  }
                >
                  Clear
                </button>
                <button
                  className="w-[70%] rounded bg-[linear-gradient(270deg,#1E94FC_0%,#AED8FF_100%)] h-full text-white shadow-[0_16px_24px_1px_rgba(0,0,0,0.04)]"
                  onClick={() => setSearchModalVisible(false)}
                >
                  Confirm
                </button>
              </div>
            </div>
            {/* Close Icon */}
            <div
              className="absolute top-4 right-5 text-[#BDBDBD] cursor-pointer"
              onClick={() => setSearchModalVisible(false)}
            >
              <svg
                className="w-[26px] h-[26px]"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
                strokeWidth={2}
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M10 14l2-2m0 0l2-2m-2 2l-2-2m2 2l2 2m7-2a9 9 0 11-18 0 9 9 0 0118 0z"
                />
              </svg>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default Market
